@font-face {
  font-family: "EismoClassic";
  src: url("/assets/fonts/EismoClassic.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoLight";
  src: url("/assets/fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBold";
  src: url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-backwards {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wummern {
  0%,
  100% {
    stop-color: green;
  } /* Normale Farbe */
  50% {
    stop-color: lightgreen;
  } /* Heller Farbton */
}

$primary-color: lightslategray;
$bg-color: rgb(0, 0, 0);
$font-size: 34px; 
