// Zuerst forward die LightGallery-Stile

$table-bg: rgb(43, 41, 41);
@use "variables" as vars; // Falls du eine Variablendatei hast
@forward 'lightgallery/css/lightgallery.css';
@forward 'lightgallery/css/lg-zoom.css';
@forward 'lightgallery/css/lg-thumbnail.css';
@forward "bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";

$table-bg: rgb(43, 41, 41);

.rotate {
  transform-origin: 200px 200px;
  /* Setzt den Drehpunkt auf die Mitte des SVG (960x700) */
  animation: rotate 3s linear infinite;
  /* Endlos-Rotation */
}

.rotate-backwards {
  transform-origin: 200px 200px;
  /* Setzt den Drehpunkt auf die Mitte des SVG (960x700) */
  animation: rotate-backwards 3s linear infinite;
  /* Endlos-Rotation */
}

radialGradient {
  animation: resize 5s infinite alternate ease-in-out;
}

#e stop {
  animation: wummern 2s infinite alternate ease-in-out;
}

body {
  margin: 0;
  padding: 0;
  background-color: black;
  color: white;
  font-family: "Roboto";
  font-style: normal;
}

.alert-danger {
  --bs-alert-bg: rgba(248, 215, 218, 0);
  font-family: "Roboto";
  color: rgb(176, 10, 28);

  button {
    background-color: rgb(176, 10, 28);
    border-radius: 0 0.375em 0.375em 0;
  }
}

.nav-link {
  color: rgb(49, 48, 48);
  font-family: 'EismoClassic';
  font-size: 36px;

  &:hover {
    color: rgb(133, 127, 127)
  }

  &:focus {
    color: rgb(133, 127, 127)
  }

  &.active {
    color: white;
  }
}

.lg-zoom-in .lg-img-wrap {
  transition: transform 0.3s linear;
}

h1 {
  font-family: 'EismoClassic';
  font-size: 2.8em;
  margin-top: 40px;
}

.table-responsive {
  .caption-top {
    text-align: right;
    margin-bottom: 5px;

    a {
      font-size: 24px;
    }
  }

  table {
    thead {
      tr {
        th {
          color: #c2bebe;
        }
      }
    }

    tbody {
      tr {
        td {
          color: #797676;
        }
      }
    }
  }
}

html {
  font-size: 16px; /* Standard für Desktop */
}

@media (max-width: 768px) { /* Tablets */
  html {
    font-size: 14px;
  }
}

@media (max-width: 480px) { /* Smartphones */
  html {
    font-size: 12px;
  }
}