.root {
  background-color: black;
  width: 100vw;
  height: 100vh;
}

.center-box {
  height: 100%;
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 576px) {
  .center-box {
    height: 300px;
    width: 300px;
  }
}

.box-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  svg {
    width: 100%;
    height: 100%;
  }
}

.box-form {
  @extend .box-bg;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 9;
  padding: 0 20px;

  form {
    input {
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 8px;
      border: none;
      font-size: 16px;
      accent-color: rgb(100, 204, 252);
      background-color: rgba(134, 211, 255, 0.315);
      font-family: "Roboto";
      box-sizing: border-box;
    }

    input::placeholder {
      font-family: "RobotoLight", sans-serif;
    }

    .first-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      gap: 10px;

      p {
        position: absolute;
        top: -60%;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        display: none;
        margin-bottom: 0px;
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
        color: rgb(110, 10, 10);

        &.show-error {
          display: block;
        }
      }
    }

    .last-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      gap: 10px;

      button {
        width: 100%;
        font-size: 20px;
        color: rgba(21, 2, 29, 0.8);
        background-color: rgba(8, 222, 250, 0.25);
        box-shadow: none;
        border: none;
        border-radius: 8px;
        padding: 8px;
      }

      p {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        display: none;
        margin-top: 10px;
        text-align: center;
        font-size: 0.8rem;
        color: rgb(110, 109, 109);

        &.show-hint {
          display: block;
        }

        a {
          text-decoration: none;
        }
      }
    }
  }
}